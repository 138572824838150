<template>
  <section class="form-block base-shadow">
      <h4 class="form-block-title clearfix">{{name}}<slot name="control"></slot></h4>
      <section class="form-block-content">
          <slot></slot>
      </section>
  </section>
</template>

<script>
export default {
    props:{
        name:{
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
.form-block{
    box-sizing: border-box;
    background-color: #fff;
    margin: 20px;
    position: relative;
    .form-block-title{
        box-sizing: border-box;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
        text-align: left;
        margin: 0;
        border-bottom: 1px solid #f1f1f1;
    }
    .form-block-content{
        box-sizing: border-box;
        padding: 15px;
    }
}
</style>