import { GetMerchantsById } from "@/views/merchants/api";
import { ShowApiError } from "@/request/error";
export default {
    data() {
        return {
            merchantId: null,
            canNotMatchContact: false,
            contactParams: {}
        }
    },
    methods: {
        onCheckMerchantData() {
            // 从松露猪推荐传进来的签约客户的id
            if (this.$route.params.type && this.$route.params.type == "MERCHANTS") {
                this.merchantId = this.$route.params.id;
                this.currentType = this.$route.params.type;
                this.getMerchantById();
            }
        },
        getMerchantById() {
            this.loading = true;
            GetMerchantsById(this.merchantId).then(res => {
                for (const key in this.form) {
                    if (Object.hasOwnProperty.call(res.data, key)) {
                        this.form[key] = res.data[key]
                    }
                }
                // 设置联系人表单参数
                this.contactParams = {
                    address: res.data.address,
                    name: res.data.contactName,
                    phoneNo: res.data.phoneNo
                }
                this.onReviewFormAddress();
                this.matchMerchantContactId(res.data.phoneNo);
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                ShowApiError("获取松露猪客户信息错误", err);
            })
        },
        matchMerchantContactId(contactPhoneNo = "") {
            if (this.contactOptions.length > 0 && contactPhoneNo !== "") {
                let target = this.contactOptions.find(item => item.phoneNo === contactPhoneNo)
                if (target) {
                    this.canNotMatchContact = false
                    this.form.mainContactId = target.id;
                } else {
                    this.canNotMatchContact = true
                    this.form.mainContactId = null;
                }
            }
        }
    }
}