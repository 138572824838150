<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="100px"
    class="demo-ruleForm"
    :disabled="submitting"
  >
    <el-form-item label="标签名称" prop="name">
      <el-input v-model="form.name" clearable placeholder="请输入" @keyup.enter.native="submitForm('form')"></el-input>
    </el-form-item>
    <el-form-item label="所属层级" prop="parentId">
      <el-select
        v-model="form.parentId"
        placeholder="请选择"
        clearable
        filterable
      >
        <el-option
          v-for="item in parentOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button @click="onCancel('form')">关 闭</el-button>
      <el-button
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PostClientTags, GetClientTagsAll } from "../api";
import { ShowApiError } from "@/request/error";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      submitting: false,
      form: {
        parentId: null,
        name: "",
      },  
      parentOptions: [],
      rules: {
        name: [{ required: true, message: "请输入标签名称", trigger: "blur" }],
      },
    };
  },
  created(){
    this.getParentData();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostClientTags(this.form)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新建成功!");
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("提交新建错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    getParentData() {
      GetClientTagsAll().then((res) => {
        let result = res.data.filter(item => item.parentId === null);
        this.parentOptions = [{ id: null, name: "顶级" }, ...result];
      });
    },
    initForm() {
      this.form = {
        parentId: null,
        name: "",
      };
      this.getParentData();
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>