import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取客户来源的列表
export function GetClientSourcesAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/sources/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取客户来源的分页列表
export function GetClientSourcesList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/sources/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的客户来源
export function GetClientSourcesById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/sources/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建客户来源
export function PostClientSources(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/clients/sources`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定客户来源的数据
export function PutClientSourcesById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/clients/sources/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定客户来源的数据
export function DeleteClientSourcesById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.delete(`/v1/clients/sources/${id}`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 批量新增
export function MultipleClientSource(params = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        params.forEach(item => {
            multipleRequests.push(apiv1.post(`/v1/clients/sources`, item))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })

}

// 批量删除
export function MultipleDeleteClientSources(multipleSelectieIds = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.delete(`/v1/clients/sources/${item}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以更新
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/sources/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以禁用/启用
export function GetDeleteCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/sources/${id}/can/delete`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}