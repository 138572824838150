<template>
  <section class="success-wrap">
    <div class="icon">
      <svg-icon iconClass="success"></svg-icon>
    </div>
    <h3 class="success-text">{{ text }}</h3>
    <p class="success-desc">{{ desc }}</p>
    <section class="control" v-if="showControl">
      <slot name="control">
      <el-button type="primary" @click="$emit('backktolist')"
        >返回列表</el-button
      >
      <el-button @click="$emit('handledetail')">查看详情</el-button>
      <el-button @click="$emit('handlewrite')">再写一份</el-button>
      </slot>
    </section>
    <section class="success-content">
      <slot></slot>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Number],
      default: "提交成功",
    },
    desc: {
      type: [String, Number],
      default: "",
    },
    showControl: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style  lang="scss" scoped>
.success-wrap{
    box-sizing: border-box;
    padding: 15px;
    font-size: 14px;
    .icon{
        text-align: center;
        padding-bottom: .2em;
        font-size: 5.8em;
        overflow: hidden;
    }
    .success-text{
        font-size: 1.8em;
        color: #333;
        text-align: center;
        overflow: hidden;
    }
    .success-desc{
        font-size: 1em;
        color: #989898;
        padding: .9em;
        line-height: 1.8em;
        text-align: center;
    }
    .control{
        text-align: center;
        padding-bottom: .9em;
        overflow: hidden;
    }
}
</style>