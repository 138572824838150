<template>
  <div class="base-tip">
    <span>{{ isExist ? "该电话已存在" : "" }}</span>
    <el-popover
      v-if="isExist"
      placement="top"
      width="500"
      trigger="click"
      v-model="togglePopover"
    >
      <!-- 这里将会有表格数据 -->
      <el-table :data="tableData" v-loading="loading" height="300px">
        <el-table-column
          property="name"
          label="客户名称"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span
              style="padding-left: 5px"
              title="点击查看详情"
              class="text-nav"
              @click="onShowDetail(scope.row)"
              >{{ scope.row.name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          property="contactName"
          label="联系人"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
              scope.row.mainContact !== null ? scope.row.mainContact.name : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="ownerName"
          label="负责人"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.ownerName }}
          </template>
        </el-table-column>
        <el-table-column property="contactTelephoneNo" label="联系电话">
          <template slot-scope="scope">
            {{
              scope.row.mainContact !== null
                ? scope.row.mainContact.phoneNo
                : ""
            }}
          </template>
        </el-table-column>
      </el-table>
      <i class="el-icon-info text-nav" slot="reference"></i>
    </el-popover>
    
  </div>
</template>

<script>
import { GetContactsList } from "@/views/client/contacts/api";
import { GetClientsList } from "@/views/client/api";
import { throttle } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    phone: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      togglePopover: false,
      tableData: [],
      isExist: false,
    };
  },
  watch: {
    phone: {
      handler() {
        throttle(() => {
          this.handleCheck();
        }, 300)();
      },
      immediate: true,
    },
    togglePopover: {
      handler(val) {
        if (this.isExist && val) {
          this.getReapetUser();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getReapetUser() {
      this.loading = true;
      GetClientsList({
        page: 0,
        size: 999,
        repeated: false,
        "contacts.phoneNo": this.phone,
      })
        .then((res) => {
          this.loading = false;
          let { content } = res.data;
          this.tableData = content;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleCheck() {
      if (isEmprty(this.phone)) {
        this.isExist = false;
        return false;
      }
      GetContactsList({
        page: 0,
        size: 9999,
        phoneNo: this.phone,
      })
        .then((res) => {
          if (res.data.content.length > 0) {
            this.isExist = true;
          } else {
            this.isExist = false;
          }
        })
        .catch(() => {
          this.isExist = false;
        });
    },
    onShowDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tip {
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: #f56c6c;
}
</style>