<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="companyForm"
    label-width="100px"
    status-icon
    class="company-ruleForm"
    :disabled="submitting"
    v-loading="loading"
    element-loading-text="表单初始化中..."
  >
    <el-form-item label="姓名" prop="name">
      <el-input
        v-model.trim="form.name"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="手机号码" prop="phoneNo">
      <el-input
        v-model.trim="form.phoneNo"
        clearable
        placeholder="请输入"
      ></el-input>
      <CheckContactPhoneRepeate class="el-form-item__error" :phone="form.phoneNo" />
    </el-form-item>
    <el-form-item label="邮箱" prop="email">
      <el-input
        v-model.trim="form.email"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="QQ" prop="qq">
      <el-input
        v-model.trim="form.qq"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="微信" prop="wx">
      <el-input
        v-model.trim="form.wx"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="区域地址" prop="address.country">
      <BasicCascader
        v-model="areaValue"
        @change="handleAreasChange"
        @onLoad="onBasicCascaderLoadSuccess"
      />
    </el-form-item>
    <el-form-item label="街道" prop="country">
      <el-input
        v-model.trim="form.address.street"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >关 闭</el-button
      >
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        :loading="submitting"
        @click="submitForm('companyForm')"
        >立即创建</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('companyForm')"
        v-if="!isEmprty(id)"
        >立即修改</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import {
  GetContactsById,
  PostContacts,
  PutContactsById,
  DuplicateContactsPhoneNo,
} from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import BasicCascader from "@/components/BasicCascader";
import { throttle } from "@/utils/common";
import CheckContactPhoneRepeate from "@/components/CheckContactPhoneRepeate";
export default {
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    BasicCascader,
    CheckContactPhoneRepeate
  },
  data() {
    return {
      loading: false,
      submitting: false,
      areaValue: [],
      areasOptions: [],
      form: {
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        email: "",
        name: "",
        phoneNo: "",
        qq: "",
        wx: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        phoneNo: [
          {
            required: true,
            validator: this.onCheckPhoneNumber,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: false,
            validator: this.onCheckEmail,
            trigger: "blur",
          },
        ],
        "address.country": [
          {
            required: true,
            message: "请选择区域地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },

  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.inintForm();
          this.loading = true;
        }
      },
      immediate: true,
      deep: true,
    },
    // 从外部传参初始化表单
    params: {
      handler() {
        if (isEmprty(this.id && Object.keys(this.params).length > 0)) {
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(this.params, key)) {
              this.form[key] = this.params[key];
            }
          }
          if (this.params.address) {
            this.onReviewAddress();
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    onCheckPhoneNumber(rule, value, callback) {
      // console.log('rule', rule)
      if (!value || value === "") {
        callback();
        return;
      } else {
        // 只在新增模式下检测电话是否重复
        if (!isEmprty(this.id)) {
          callback();
          return false;
        }
        if (value && value !== "") {
          let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
          if (!reg.test(value)) {
            callback(new Error("手机号码格式不正确"));
          } else {
            throttle(() => {
              DuplicateContactsPhoneNo(value)
                .then((res) => {
                  this.isUsernameExist = res.data;
                  if (res.data) {
                    callback(new Error("该电话号码已经存在"));
                  } else {
                    callback();
                  }
                })
                .catch((err) => {
                  callback(new Error("电话号码查重异常，请稍后重试"));
                  ShowApiError("电话号码查重请求失败", err);
                });
            }, 800)();
          }
        }
      }
    },
    onCheckEmail(rule, value, callback) {
      // console.log('rule', rule)
      if (!value || value === "") {
        callback();
        return;
      }
      let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("邮箱格式不正确"));
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostContacts(this.form)
            .then((res) => {
              this.submitting = false;
              this.inintForm();
              this.$message.success("新增成功");
              this.onEmitSuccess(res.data);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutContactsById(this.id, this.form)
            .then((res) => {
              this.submitting = false;
              this.onEmitSuccess(res.data);
              this.inintForm();
              this.$message.success("修改成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetContactsById(id)
        .then((res) => {
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.onReviewAddress();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onReviewAddress() {
      if (this.form.address.region === "") {
        this.areaValue = [
          this.form.address.country,
          this.form.address.province,
          this.form.address.city,
        ];
      } else {
        this.areaValue = [
          this.form.address.country,
          this.form.address.province,
          this.form.address.city,
          this.form.address.region,
        ];
      }
    },
    onCancel() {
      this.$emit("cancel");
    },
    onEmitSuccess(data={}) {
      this.$emit("success", data);
    },
    inintForm() {
      this.form = {
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        email: "",
        name: "",
        phoneNo: "",
        qq: "",
        wx: "",
      };
      this.areaValue = [];
    },
    handleAreasChange(val) {
      this.form.address.country = val[0] ? val[0] : "";
      this.form.address.province = val[1] ? val[1] : "";
      this.form.address.city = val[2] ? val[2] : "";
      this.form.address.region = val[3] ? val[3] : "";
    },
    onBasicCascaderLoadSuccess() {
      this.loading = false;
    },
  },
};
</script>

<style>
</style>