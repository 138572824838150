<template>
  <div class="tag-item-component">
    <p class="tag-type-title">
      <i class="el-icon-price-tag"></i>
      {{ tagGroup.name }}
    </p>
    <div class="tag-type-content">
      <span
        class="tag-item"
        :class="[selectIds.includes(tagItem.id) ? 'active' : '']"
        v-for="tagItem in tagGroup.subs"
        :key="tagItem.id"
        @click="onCheckBoxChange(tagItem.id, tagItem)"
      >
        <span class="check-box">
          <i class="el-icon-check" v-show="selectIds.includes(tagItem.id)"></i>
        </span>
        <span class="tag-item-name">{{ tagItem.name }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tagGroup: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onCheckBoxChange(id,item) {
      this.$emit("change", id,item);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-item-component {
  box-sizing: border-box;
  .tag-item {
    box-sizing: border-box;
    display: inline-block;
    margin-left: 0 !important;
    margin: 0 20px 20px 0;
    padding: 0 10px;
    height: 40px;
    line-height: 38px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    overflow: hidden;
    .check-box {
      display: inline-block;
      width: 14px;
      height: 14px;
      text-align: center;
      line-height: 14px;
      font-size: 14px;
      border: 1px solid #ddd;
      border-radius: 4px;
      vertical-align: middle;
      overflow: hidden;
    }
    .tag-item-name {
      margin-left: 5px;
    }
    &:hover {
      color: #409eff;
      .check-box {
        border-color: #409eff;
      }
    }
    &.active {
      border-color: #409eff;
      color: #409eff;
      .check-box {
        background-color: #409eff;
        color: #fff;
        border-color: #409eff;
      }
    }
  }
  .tag-type-title {
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 16px;
    color: #000;
    text-align: left;
    padding: 10px 0;
    font-weight: bolder;
  }
}
</style>