import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取全部的跟进状态列表
export function GetFollowUpsStatusesAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/statuses/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取全部跟进状态的树形列表
export function GetFollowUpsStatusesTree() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/statuses/tree`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取客户来源的分页列表
export function GetFollowUpsStatusesList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/statuses/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的客户来源
export function GetFollowUpsStatusesById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/statuses/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建客户来源
export function PostFollowUpsStatuses(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/clients/follow_ups/statuses`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定客户来源的数据
export function PutFollowUpsStatusesById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/clients/follow_ups/statuses/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 删除指定客户来源的数据
export function DeleteFollowUpsStatusesById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.delete(`/v1/clients/follow_ups/statuses/${id}`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 批量新增
export function MultiplePostFollowUpsStatuses(params = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        params.forEach(item => {
            multipleRequests.push(apiv1.post(`/v1/clients/follow_ups/statuses`, item))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })

}

// 批量删除
export function MultipleDeleteFollowUpsStatuses(multipleSelectieIds = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.delete(`/v1/clients/follow_ups/statuses/${item}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以更新
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/statuses/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 检查是否可以禁用/启用
export function GetDeleteCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/statuses/${id}/can/delete`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}