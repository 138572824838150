<template>
  <div class="custom-form-item">
    <div class="form-content"><slot></slot></div>
    <div class="add-item-btn">
      <el-button type="text" @click="handleRefresh" icon="el-icon-refresh" v-show="canRefresh">刷新</el-button>
      <el-button type="text" @click="handleAdd" icon="el-icon-plus" v-show="canAdd">添加</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    canAdd: {
      type: Boolean,
      default: true
    },
    canRefresh: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleAdd() {
      this.$emit("handleAdd");
    },
    handleRefresh(){
      this.$emit("handleRefresh")
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-form-item {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .form-content {
    flex: 1;
    height: 100%;
  }
  .add-item-btn {
    box-sizing: border-box;
    padding-left: 5px;
    flex: auto 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
  }
}
</style>