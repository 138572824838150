<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="120px"
    class="demo-ruleForm"
    :disabled="submitting"
  >
    <el-form-item label="名称" prop="name">
      <el-input
        v-model="form.name"
        clearable
        placeholder="请输入"
        @keyup.enter.native="submitForm('form')"
      ></el-input>
    </el-form-item>
    <el-form-item label="所属层级" prop="parentId">
      <el-select
        v-model="form.parentId"
        placeholder="请选择"
        clearable
        filterable
      >
        <el-option
          v-for="item in parentOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="次序" prop="seq">
      <el-input-number
        v-model="form.seq"
        label="请输入"
        :min="1"
        @keyup.enter.native="submitForm('form')"
      ></el-input-number>
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button @click="onCancel('form')">关 闭</el-button>
      <el-button
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PostFollowUpsStatuses, GetFollowUpsStatusesAll } from "../api";
import { ShowApiError } from "@/request/error";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      submitting: false,
      form: {
        name: "",
        parentId: null,
        seq: 1,
      },
      parentOptions: [],
      rules: {
        name: [
          { required: true, message: "请输入跟进状态名称", trigger: "blur" },
        ],
        seq: [
          {
            required: true,
            message: "请输入次序",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.getParentData();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostFollowUpsStatuses(this.form)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新建成功!");
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("提交新建错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getParentData() {
      GetFollowUpsStatusesAll().then((res) => {
        let result = res.data.filter(item => item.parentId == null)
        this.parentOptions = [{ id: null, name: "顶级" }, ...result];
      });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        name: "",
        parentId: null,
        seq: 1,
      };
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>