<template>
  <el-cascader
    v-bind="$props"
    v-model="areaValue"
    :disabled="loading"
    :options="areasOptions"
    filterable
    :placeholder="currentPlaceholder"
    style="width: 100%"
    clearable
    ref="customCascader"
    @change="handleAreasChange"
  >
  </el-cascader>
</template>

<script>
// import { GetFormatAreaInfo } from "@/utils/common";
// import areaCascader from "@/assets/areas/areaCascader.json";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    showAllData:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPlaceholder: "",
      loading: false,
      areaValue: [],
      areasOptions: [],
    };
  },
  computed: {
    _areaCascaderOptions() {
      return this.$store.getters.areaCascaderOptions;
    },
    _chinaCascaderOptions() {
      return this.$store.getters.chinaCascaderOptions;
    },
  },
  watch: {
    value: {
      handler() {
        this.initValue();
        this.initOptions();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initValue() {
      if (this.value.length > 0) {
        this.areaValue = this.value;
      } else {
        this.areaValue = [];
      }
    },
    initOptions() {
      if (this.areasOptions.length > 0) {
        this.loading = false;
        this.setPlaceholder();
        this.$emit("onLoad");
        return false;
      }
      this.areasOptions = this.showAllData ? this._areaCascaderOptions : this._chinaCascaderOptions;
      this.loading = false;
      this.setPlaceholder();
      this.$emit("onLoad");
    },
    handleAreasChange(val) {
      this.$emit("change", val);
    },
    setPlaceholder(text = "") {
      if (text == "") {
        this.currentPlaceholder =
          this.placeholder !== ""
            ? this.placeholder
            : "输入关键字搜索，例如广州";
      } else {
        this.currentPlaceholder = text;
      }
    },
  },
};
</script>

<style>
</style>