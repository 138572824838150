import { GetClientTagsAll } from "@/views/client/clientTags/api";
import { GetClientStagesAll } from "@/views/client/clientStages/api";
import { GetClientSourcesAll } from "@/views/client/clientSources/api";
import { GetFollowUpsStatusesAll, GetFollowUpsStatusesTree } from "@/views/client/clientFollowUpsStatuses/api";
import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
import { GetClientTypesAll } from "@/views/client/clientTypes/api";
import { GetContactsAll } from "@/views/client/contacts/api";
import { checkPhoneNumber } from "@/utils/validate";
import { compareReverse } from "@/utils/common";
export default {
    data() {
        return {
            rules: {
                name: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
                // brand: [{ required: true, message: "请输入客户品牌", trigger: "blur" }],
                followUpStatusId: [
                    {
                        required: true,
                        message: "请选择跟进状态",
                        trigger: ["blur", "change"],
                    },
                ],
                ownerId: [
                    {
                        required: true,
                        message: "请选择客户负责人",
                        trigger: ["blur", "change"],
                    },
                ],
                sourceId: [
                    {
                        required: true,
                        message: "请选择客户来源",
                        trigger: ["blur", "change"],
                    },
                ],
                stageId: [
                    {
                        required: true,
                        message: "请选择客户阶段",
                        trigger: ["blur", "change"],
                    },
                ],
                typeId: [
                    {
                        required: true,
                        message: "请选择客户类型",
                        trigger: ["blur", "change"],
                    },
                ],
                mainContactId: [
                    {
                        required: true,
                        message: "请选择客户主要联系人",
                        trigger: ["blur", "change"],
                    },
                ],
                "address.country": [
                    {
                        required: true,
                        message: "请选择区域地址",
                        trigger: ["blur", "change"],
                    },
                ],
                "mainContact.name": [
                    {
                        required: true,
                        message: "请填写主联系人姓名",
                        trigger: ["blur"],
                    },
                ],
                "mainContact.phoneNo": [
                    // {
                    //     required: true,
                    //     message: "请填写主联系人电话",
                    //     trigger: ["blur"],
                    // },
                    // { type: 'number', message: '电话号码必须为数字'}
                    { required: true,validator: checkPhoneNumber, trigger: ["change", "blur"] }
                    

                ],
                // "mainContact.addressValue": [
                //     {
                //         required: true,
                //         message: "请选择主联系人地址",
                //         trigger: ["blur", "change"],
                //     },
                // ],
            },
            tagOptions: [],
            ownerOptions: [],
            sourceOptions: [],
            stageOptions: [],
            typeOptions: [],
            contactOptions: [],
            followUpStatusOptions: [],
            followUpStatusTreeOptions: [],
            addFollowUpStatusDialog: false,
            addContactDialog: false,
            addTypeDialog: false,
            addStageDialog: false,
            addSourceDialog: false,
            selectTagDialog: false,
        }
    },
    methods: {
        initFormSelection() {
            this.getTagOptions();
            this.getStageOptions();
            this.getSourceOptions();
            this.getFollowUpStatusOptions();
            this.getTypeOptions();
            this.getContactOptions();
            this.getOwnerOptions();
            this.getFollowUpsStatusesTree();
        },
        addFollowUpStatusOption() {
            this.addFollowUpStatusDialog = true;
        },
        onAddFollowUpStatusSuccess() {
            this.getFollowUpStatusOptions();
        },
        addContactOption() {
            this.addContactDialog = true;
        },
        onAddContactsSuccess() {
            this.getContactOptions();
        },
        addTypeOption() {
            this.addTypeDialog = true;
        },
        onAddTypeFormSuccess() {
            this.getTypeOptions();
        },
        addStageOption() {
            this.addStageDialog = true;
        },
        onAddStageFormSuccess() {
            this.getStageOptions();
        },
        addSourceOption() {
            this.addSourceDialog = true
        },
        onAddSourceFormSuccess() {
            this.getSourceOptions();
        },
        onSetTagCancel() {
            this.selectTagDialog = false;
        },
        onSetTagSuccess(ids, items) {
            this.form.tagIds = ids;
            this.tags = items;
            this.selectTagDialog = false;
        },
        getTagOptions() {
            GetClientTagsAll().then(res => {
                this.tagOptions = res.data.filter(item => item.parentId !== null);
            })
            // .catch(err => {
            //     ShowApiError("获取标签选单数据失败", err)
            // })
        },
        getStageOptions() {
            GetClientStagesAll().then(res => {
                this.stageOptions = res.data;
            })
            // .catch(err => {
            //     ShowApiError("获取阶段选单数据失败", err)
            // })
        },
        getSourceOptions() {
            GetClientSourcesAll().then(res => {
                this.sourceOptions = res.data;
            })
            // .catch(err => {
            //     ShowApiError("获取来源选单数据失败", err)
            // })
        },
        getFollowUpStatusOptions() {
            GetFollowUpsStatusesAll().then(res => {
                this.followUpStatusOptions = res.data;
            })
            // .catch(err => {
            //     ShowApiError("获取跟进状态选单数据失败", err)
            // })
        },
        getFollowUpsStatusesTree() {
            GetFollowUpsStatusesTree()
                .then((res) => {
                    this.followUpStatusTreeOptions = this.formatData(res.data);
                })
            // .catch((err) => {
            //     ShowApiError("获取跟进状态树形选单失败", err);
            // });
        },
        getTypeOptions() {
            GetClientTypesAll().then(res => {
                this.typeOptions = res.data;
            })
            // .catch(err => {
            //     ShowApiError("获取类型选单数据失败", err)
            // })
        },
        getContactOptions() {
            GetContactsAll().then(res => {
                this.contactOptions = res.data;
            })
            // .catch(err => {
            //     ShowApiError("获取联系人选单数据失败", err)
            // })
        },
        getOwnerOptions() {
            GetClientOwnersAll().then(res => {
                this.ownerOptions = res.data;
            })
            // .catch(err => {
            //     ShowApiError("获取负责人选单数据失败", err)
            // })
        },
        // 格式化数据
        formatData(data = []) {
            let results = [];
            if (data.length > 0) {
                data.forEach((item) => {
                    if (item.subs.length > 0) {
                        results.push({
                            ...item,
                            subs: this.formatData(item.subs),
                        });
                    } else {
                        delete item.subs
                        results.push(item)
                    }
                });
            }
            return results.sort(compareReverse("seq"));
        },

    }
}